import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import SelectFetchLoading from 'components/SelectFetchLoading';
import { useFetch } from 'hooks';
import React from 'react';
import { LanguageApi } from 'types/Language.type';

function SelectLanguage({
  value,
  onChange,
  error,
  helperText,
  placeholder,
  name,
}: {
  value: number | null;
  onChange: (event: SelectChangeEvent<string | number>, child: React.ReactNode) => void;
  error?: boolean;
  helperText?: string | false;
  placeholder?: string;
  name: string;
}) {
  const { data } = useFetch<LanguageApi>('/api/combo-box/language');
  return (
    <FormControl
      variant="outlined"
      {...{ error }}
      sx={{ width: '260px', marginLeft: '16px' }}
      margin="normal"
    >
      <InputLabel id="select-language-label">{'Idioma'}</InputLabel>
      <Select
        {...{ name, placeholder }}
        labelId="select-language-label"
        id="select-language"
        onChange={onChange}
        value={value ? value : ''}
        label={'Idioma'}
      >
        {data?.languages.map(({ value: id, label }) => (
          <MenuItem key={id} value={id}>
            {label}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

SelectLanguage.Loading = function SelectCustomerLoading() {
  return <SelectFetchLoading label="Idioma" />;
};

export default SelectLanguage;
