import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import SelectFetchLoading from 'components/SelectFetchLoading';
import { useFetch } from 'hooks';
import React from 'react';
import { CountryApi } from 'types/Country.type';

function SelectCountry({
  value,
  onChange,
  error,
  helperText,
  placeholder,
  name,
  initialValueLabel,
  setValue,
}: {
  value: number | null;
  onChange: (event: SelectChangeEvent<string | number>, child: React.ReactNode) => void; //(e: React.ChangeEvent<HTMLSelectElement>) => void;
  error?: boolean;
  helperText?: string | false;
  placeholder?: string;
  name: string;
  initialValueLabel: string;
  setValue: (value: number) => void;
}) {
  const { data } = useFetch<CountryApi>('/api/combo-box/country');

  React.useEffect(
    function setInitialValue() {
      if (!value) {
        const newValue = data?.countries.find((country) => country.label === initialValueLabel);
        newValue && setValue(newValue.value);
      }
    },
    [data, initialValueLabel, value, setValue]
  );

  return (
    <FormControl
      margin="normal"
      variant="outlined"
      {...{ error }}
      fullWidth
      sx={{
        marginLeft: '8px',
      }}
    >
      <InputLabel id="select-country-label">{'País'}</InputLabel>
      <Select
        {...{ name, placeholder }}
        labelId="select-country-label"
        id="select-country"
        onChange={onChange}
        value={value ? value : ''}
        label={'País'}
      >
        {data?.countries.map((country) => (
          <MenuItem key={country.value} value={country.value}>
            {country.label}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

SelectCountry.Loading = function SelectCustomerLoading() {
  return <SelectFetchLoading label="País" />;
};

export default SelectCountry;
