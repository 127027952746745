import { Alert, Box, Button, CircularProgress, Paper } from '@mui/material';
import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { v4 as uuidv4 } from 'uuid';

function FetchBoundary({ children }: { children?: React.ReactNode }) {
  const [id, setId] = React.useState(uuidv4());
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        setId(uuidv4());
        window.location.reload();
      }}
      resetKeys={[id]}
    >
      <Suspense fallback={<DefaultLoading />}>{children}</Suspense>
    </ErrorBoundary>
  );
}

function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: { message: string };
  resetErrorBoundary: React.MouseEventHandler;
}) {
  return (
    <Paper elevation={0}>
      <Alert variant="outlined" severity="error">
        Algo deu errado... {error.message}
        <Button color="error" onClick={resetErrorBoundary}>
          Tente novamente
        </Button>
      </Alert>
    </Paper>
  );
}

function DefaultLoading() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
      }}
    >
      <CircularProgress />
    </Box>
  );
}

export default FetchBoundary;
