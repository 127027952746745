import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import React from 'react';

function MaterialUiTheme({ children }: { children: React.ReactNode }) {
  const oldTheme = useTheme();
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          background: {
            default: '#f2f2f2',
            paper: '#fff',
          },
          primary: {
            ...oldTheme.palette.primary,
            contrastText: 'white',
          },
        },
        components: {
          MuiTypography: {
            defaultProps: {
              color: 'textPrimary',
            },
          },
          MuiTextField: {
            defaultProps: {
              variant: 'outlined',
            },
          },
          MuiInputBase: {
            styleOverrides: {
              input: {
                '&:-webkit-autofill': {
                  transitionDelay: '9999999s', // 9999999 / 60 / 60 = 2777,7775 hours to change the input color when autofill
                },
              },
            },
          },
        },
        typography: {
          body2: {
            fontWeight: 300,
          },
        },
      }),
    [oldTheme]
  );
  
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default MaterialUiTheme;
