import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useFetch } from 'hooks';
import { Link, useParams } from 'react-router-dom';
import { SCONDCompanyListApi, SCONDMasterCompanyInfoApi } from 'types/SCOND.type';
import MenuListComposition from './Menu';

export default function SCONDCompaniesFromMasterPage() {
  const { masterCompanyId } = useParams<{ masterCompanyId: string }>();
  const { data, mutate } = useFetch<SCONDCompanyListApi>(
    `/api/scond/master-company/${masterCompanyId}/companies`
  );

  const list = data?.companiesList || [];

  return (
    <Box>
      <Header masterCompany={data?.masterCompany} />
      {list ? (
        <Paper
          sx={{
            mt: 3,
            display: 'flex',
            flex: '1 1 auto',
            overflow: 'hidden',
          }}
        >
          <TableContainer
            sx={{
              maxHeight: 'calc(100vh - 250px)',
            }}
          >
            <Table sx={{ minWidth: 650 }} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="right">Código</TableCell>
                  <TableCell>Razão social</TableCell>
                  <TableCell>Nome fantasia</TableCell>
                  <TableCell>CNPJ</TableCell>
                  <TableCell align="left">Integração</TableCell>
                  <TableCell align="left">Ação</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map(company => (
                  <TableRow
                    key={company.empresaId}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                    hover
                  >
                    <TableCell component="th" scope="row" align="right">
                      {company.empresaId}
                    </TableCell>
                    <TableCell>{company.razaoSocial}</TableCell>
                    <TableCell>{company.nomeFantasia}</TableCell>
                    <TableCell>{company.cnpj}</TableCell>
                    <TableCell align="left">
                      {company.scondIntegradorId ? (
                        `${company.scondIntegradorId} - ${company.integrador?.razaoSocial} (${company.integrador?.nome})`
                      ) : (
                        <Link
                          to={`/scond/segware-integration/${masterCompanyId}/companies/${company.empresaId}/integrate`}
                        >
                          <Button>Integrar</Button>
                        </Link>
                      )}
                    </TableCell>
                    <TableCell align="left">
                      <MenuListComposition {...{ company, mutate }} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Paper
          sx={{
            p: 2,
          }}
        >
          <Typography variant="h6">Sem dados para mostrar</Typography>
        </Paper>
      )}
    </Box>
  );
}

function Header({ masterCompany }: { masterCompany?: SCONDMasterCompanyInfoApi }) {
  return (
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Typography variant="h4">
            Empresas da master {masterCompany?.masterCompanyId} - {masterCompany?.razaoSocial}
          </Typography>
        </Box>

        <Typography variant="h6">
          Lista com as empresas da master company para fazer integração com o SCond (Center)
        </Typography>
      </Box>
    </Paper>
  );
}
