import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React from 'react';

function SelectLicenseType({
  value,
  onChange,
  error,
  helperText,
  placeholder,
  name,
}: {
  value: string | null;
  onChange: (event: SelectChangeEvent<string>, child: React.ReactNode) => void;
  error?: boolean;
  helperText?: string | false;
  placeholder?: string;
  name: string;
}) {
  return (
    <FormControl
      variant="outlined"
      fullWidth
      {...{ error }}
      sx={{
        marginLeft: '8px',
      }}
      margin="normal"
    >
      <InputLabel id="select-licenseType-label">Tipo de licença</InputLabel>
      <Select
        {...{ name, placeholder }}
        labelId="select-licenseType-label"
        id="select-licenseType"
        onChange={onChange}
        value={value ? value : ''}
        label="Tipo de licença"
      >
        <MenuItem key="PROFESSIONAL" value="PROFESSIONAL">
          Professional
        </MenuItem>
        <MenuItem key="EXPRESS" value="EXPRESS">
          Express
        </MenuItem>
        <MenuItem key="ENTERPRISE" value="ENTERPRISE">
          Enterprise
        </MenuItem>
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default SelectLicenseType;
