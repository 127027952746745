import React from 'react';
import { Box, Paper, Typography, useTheme } from '@mui/material';
import CanaryNewForm from './CanaryNewForm';

function CanaryNew() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        overflow: 'hidden',
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Paper
        sx={{
          padding: theme.spacing(2),
        }}
      >
        <Typography variant="h4">Disponibilizar recurso Canary</Typography>
      </Paper>
      <Paper
        sx={{
          marginTop: theme.spacing(3),
          padding: theme.spacing(2),
          overflow: 'hidden',
          display: 'flex',
        }}
      >
        <CanaryNewForm />
      </Paper>
    </Box>
  );
}

export default CanaryNew;
