import { LoadingButton } from '@mui/lab';
import { Box, Paper, TextField, useTheme, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import axios from 'axios';
import { useMessages } from 'hooks';
import { useLocalStorage } from 'react-use';
import { userKey } from 'consts';
import { User } from 'types/User.type';
import { useNavigate } from 'react-router-dom';

function Login() {
  const { enqueueError } = useMessages();
  const theme = useTheme();
  const navigate = useNavigate();
  const [, setUser] = useLocalStorage(userKey, '');
  const validationSchema = yup.object({
    username: yup.string().required(),
    password: yup.string().required(),
  });
  const formik = useFormik({
    initialValues: { username: '', password: '' },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const newUser = await axios.post<User>('/api/login', values);
        setUser(newUser.data && JSON.stringify(newUser.data));
        setSubmitting(false);
        navigate('/company');
      } catch (error) {
        setUser('');
        if (axios.isAxiosError(error))
          enqueueError(`Erro ao fazer login\n${error?.response?.data?.message || ''}`);
        throw error;
      }
    },
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Paper sx={{ padding: theme.spacing(4) }} elevation={2}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4">Sigma Cloud Admin</Typography>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            id="username"
            name="username"
            label="Usuário"
            value={formik.values.username}
            onChange={formik.handleChange}
            error={formik.touched.username && Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
            fullWidth
            margin="normal"
          />
          <TextField
            id="password"
            name="password"
            label="Senha"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            type="password"
            fullWidth
            margin="normal"
          />
          <LoadingButton
            loading={formik.isSubmitting}
            color="primary"
            variant="contained"
            fullWidth
            size="large"
            type="submit"
          >
            Entrar
          </LoadingButton>
        </form>
      </Paper>
    </Box>
  );
}

export default Login;
