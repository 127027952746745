import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import OnboardingDialogVMS from 'components/OnboardingDialogVMS';
import { useFetch, useMessages } from 'hooks';
import { Header } from 'pages/VMSCloud/components';
import { useVmsAPI } from 'pages/VMSCloud/hook/useVMS';
import { MasterCompanyApiList } from 'pages/VMSCloud/types';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function CompanyListPage() {
  const { data } = useFetch<MasterCompanyApiList>('/api/vms-cloud/companies');
  const theme = useTheme();
  const navigate = useNavigate();
  const { enqueueError } = useMessages();
  const [openOnboardingModal, setOpenOnboardingModal] = useState({
    id: 0,
    status: false,
  });
  const { disableMasterCompany, enableMasterCompany } = useVmsAPI();

  const [loading, setLoading] = React.useState({
    status: false,
    companyId: 0,
  });

  const handleEnable = async (companyId: number) => {
    try {
      setLoading({
        status: true,
        companyId,
      });

      await enableMasterCompany(companyId);
    } catch (error) {
      if (error instanceof Error)
        enqueueError(`Falha na Ativação da Master Company: ${error.message}`);
      console.error(error);
    }
    setLoading({
      status: false,
      companyId,
    });
  };

  const handleDisable = async (companyId: number) => {
    try {
      setLoading({
        status: true,
        companyId,
      });

      await disableMasterCompany(companyId);
    } catch (error) {
      if (error instanceof Error)
        enqueueError(`Falha na Desativação da Master Company: ${error.message}`);
      console.error(error);
    }
    setLoading({
      status: false,
      companyId,
    });
  };

  return (
    <Box>
      <Header
        title="VMS Cloud"
        subtitle="Lista de clientes do VMS"
        tooltipText="Ao desativar uma Master Company, estará inativando todos os usuários e parando o streaming das câmeras vinculadas a master company."
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: theme.spacing(2),
          }}
        >
          <Button variant="contained" onClick={() => navigate('/vms/new')} color="primary">
            Criar Master Company
          </Button>
          <Button onClick={() => navigate(-1)}>Voltar</Button>
        </Box>
      </Header>
      {data ? (
        <Paper
          sx={{
            marginTop: theme.spacing(3),
            display: 'flex',
            flex: '1 1 auto',
          }}
        >
          <TableContainer
            sx={{
              maxHeight: 'calc(100vh - 300px)',
            }}
          >
            <Table sx={{ minWidth: 650, maxHeight: '100%' }} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="right">Código</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Status de implantação:</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell align="center">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.companies.map(company => (
                  <TableRow
                    key={company.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                    hover
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      align="right"
                      width="1%"
                      onClick={() => navigate(`/vms/${company.id}`)}
                    >
                      {company.id}
                    </TableCell>
                    <TableCell width="25%" onClick={() => navigate(`/vms/${company.id}`)}>
                      {company.name}
                    </TableCell>
                    <TableCell width="10%" align="left">
                      {company?.onboarding ? (
                        <Button
                          variant="contained"
                          color="info"
                          onClick={() => {
                            setOpenOnboardingModal({
                              id: company.id,
                              status: true,
                            });
                          }}
                          disabled={!company.status}
                        >
                          IMPLANTAÇÃO
                        </Button>
                      ) : (
                        <Button variant="outlined" disabled>
                          PRODUÇÃO
                        </Button>
                      )}
                    </TableCell>
                    <TableCell width="5%" align="left">
                      <LoadingButton
                        variant="contained"
                        color={company.status ? 'error' : 'success'}
                        onClick={() =>
                          company.id &&
                          (company.status ? handleDisable(company.id) : handleEnable(company.id))
                        }
                        loading={company.id === loading.companyId && loading.status}
                      >
                        {company.id === loading.companyId && loading.status ? (
                          <CircularProgress size="1.6rem" color="inherit" />
                        ) : company.status ? (
                          'Desativar'
                        ) : (
                          'Ativar'
                        )}
                      </LoadingButton>
                    </TableCell>
                    <TableCell width="10%" align="center">
                      <Button
                        onClick={() => navigate(`/vms/${company.id}/new-user`)}
                        variant="contained"
                        sx={{ ml: 4 }}
                      >
                        Adicionar usuário
                      </Button>
                    </TableCell>
                    {company.status && (
                      <OnboardingDialogVMS
                        company={company}
                        openOnboardingModal={openOnboardingModal}
                        onCloseOnboardingModal={() =>
                          setOpenOnboardingModal({
                            id: company.id,
                            status: false,
                          })
                        }
                      />
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Paper
          sx={{
            padding: theme.spacing(2),
          }}
        >
          <Typography variant="h6">Sem dados para mostrar</Typography>
        </Paper>
      )}
    </Box>
  );
}

export default CompanyListPage;
