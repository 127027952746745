import {
  CompanyListPage,
  CompanyNewUserPage,
  CompanyNewPage,
  CompanyEditPage,
  CompanyViewPage,
  CompanyVinculatedNewPage,
  CompanyVMSListPage,
} from './pages';

export const VMS = {
  CompanyListPage,
  CompanyNewPage,
  CompanyEditPage,
  CompanyNewUserPage,
  CompanyViewPage,
  CompanyVinculatedNewPage,
  CompanyVMSListPage,
};
