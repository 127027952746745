import React, { useState, useCallback } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { mutate } from 'swr';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useFetch, useMessages } from 'hooks'
import { CANARY_FUNCTIONALITYS } from 'consts'
import { CanaryListApi } from 'types/Canary.type';

function Canary() {
  const { data } = useFetch<CanaryListApi>('/api/canary');
  const theme = useTheme();
  const navigate = useNavigate();
  const { enqueueError, enqueueSuccess } = useMessages();
  
  const [open, setOpen] = useState<boolean | number>(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  
  const handleClickOpen = useCallback((id: number) => {
    setOpen(id);
  }, []);
  
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleDelete = useCallback(async () => {
    setLoadingRemove(true);
    try {
      await axios.delete(`/api/canary/remove/${open}`);
      mutate<CanaryListApi>(
        '/api/canary',
        (dataSwr) =>
          dataSwr && {
            ...dataSwr,
            canarys: dataSwr.canarys.filter(
              (item) => item.id !== open
            ),
          }
      );
      enqueueSuccess('Canary removido com sucesso!');
      setLoadingRemove(false);
    } catch (error) {
      setLoadingRemove(false);
      if (axios.isAxiosError(error))
        enqueueError(`Erro ao remover canary\n${error?.response?.data?.message || ''}`);
      throw error;
    } finally {
      handleClose();
    }
  }, [open, handleClose, enqueueError, enqueueSuccess]);

  return (
    <Box
      sx={{
        overflow: 'hidden',
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Paper
        sx={{
          padding: theme.spacing(2),
          display: 'flex', 
          flexDirection: 'row',
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography variant="h4">Canary</Typography>
          <Typography variant="h6">Disponibilizar funcionalidades canary para os clientes</Typography>
        </Box>
        
        <Button sx={{ alignSelf: 'center' }} onClick={() => navigate('/canary/new')} variant="contained" size="large">
          Novo
        </Button>
      </Paper>
      {data?.canarys?.length ? (
        <Paper
          sx={{
            marginTop: theme.spacing(3),
            display: 'flex',
            flex: '1 1 auto',
            overflow: 'hidden',
          }}
        >
          <TableContainer>
            <Table sx={{ minWidth: 650 }} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Master company</TableCell>
                  <TableCell>Funcionalidade</TableCell>
                  <TableCell align="center">Ação</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.canarys.map((item) => (
                  <TableRow
                    key={item.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                    hover
                  >
                    <TableCell>{item.masterCompanyId} - {item.fantasia}</TableCell>
                    <TableCell>{CANARY_FUNCTIONALITYS.find((func) => func.value === item.functionality)?.label ?? item.functionality}</TableCell>
                    <TableCell align="center">
                      <LoadingButton color="error" onClick={() => handleClickOpen(item.id)} variant="contained">
                        <DeleteIcon />
                      </LoadingButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Dialog
            open={open > 0}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Confirma a exclusão do canary?</DialogTitle>
            <DialogActions>
              <Button onClick={handleClose}>Não</Button>
              <LoadingButton color="error" loading={loadingRemove} onClick={handleDelete} autoFocus>
                Sim
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </Paper>
      ) : (
        <Paper
          sx={{
            padding: theme.spacing(2),
          }}
        >
          <Typography variant="h6">Sem dados para mostrar</Typography>
        </Paper>
      )}
    </Box>
  );
}

export default Canary;
