import React from 'react';
import {
  FormControl,
  FormHelperText,
  TextField,
  Autocomplete,
} from '@mui/material';
import SelectFetchLoading from 'components/SelectFetchLoading';
import { useFetch } from 'hooks';
import { MasterCompanySelectApi, MasterCompanySelect } from 'types/MasterCompany.type';

function SelectMasterCompany({
  value,
  onChange,
  error,
  helperText,
  placeholder,
  name,
}: {
  value: MasterCompanySelect | null;
  onChange: (value: MasterCompanySelect | null) => void;
  error?: boolean;
  helperText?: string | false;
  placeholder?: string;
  name: string;
}) {
  const { data } = useFetch<MasterCompanySelectApi>('/api/combo-box/mastercompany');

  return (
    <FormControl
      margin="normal"
      variant="outlined"
      {...{ error }}
      fullWidth
      sx={{
        marginLeft: '8px',
      }}
    >
      <Autocomplete<MasterCompanySelect>
        {...{ name, placeholder }}
        id="select-client"
        value={value}
        onChange={(event: unknown, newValue: MasterCompanySelect | null) => {
          onChange(newValue);
        }}
        options={data?.mastercompanys ?? []}
        renderInput={(params) => <TextField {...params} label="Master company" />}
        getOptionLabel={(option) => `${option.id} - ${option.label}`}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

SelectMasterCompany.Loading = function SelectCustomerLoading() {
  return <SelectFetchLoading label="Master company" />;
};

export default SelectMasterCompany;
