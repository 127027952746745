import axios from 'axios';
import useMessages from '../../../hooks/useMessages';
import { getMessage } from '../../../helpers/vms_error_keys';
import { mutate } from 'swr';
import { useNavigate } from 'react-router-dom';
import { CompanyNewData } from '../types';

export const useVmsAPI = () => {
  const { enqueueSuccess, enqueueError } = useMessages();
  const navigate = useNavigate();

  const createMasterCompany = async (values: CompanyNewData) => {
    try {
      await axios.post('/api/vms-cloud/company', values);
      enqueueSuccess('Empresa criada com sucesso');
      navigate('/vms/list');
      mutate(`/api/vms-cloud/companies`);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueError(`${getMessage(error?.response?.data?.message)}`);
      }
      throw error;
    }
  };

  const updateMasterCompany = async ({
    id,
    name,
    alwaysPlay,
  }: {
    id: number;
    name: string;
    alwaysPlay: boolean;
  }) => {
    try {
      await axios.put(`/api/vms-cloud/company/${id}`, {
        name,
        alwaysPlay,
      });
      enqueueSuccess('Master Company foi alterada com sucesso');
      navigate('/vms/list');
      mutate(`/api/vms-cloud/companies`);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueError(`${getMessage(error?.response?.data?.message)}`);
      }
      throw error;
    }
  };

  const enableMasterCompany = async (companyId: number) => {
    try {
      await axios.put(`/api/vms-cloud/company/${companyId}/enable`);
      enqueueSuccess('Master Company foi ativada com sucesso');
      mutate(`/api/vms-cloud/companies`);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueError(`${getMessage(error?.response?.data?.message)}`);
      }
      throw error;
    }
  };

  const disableMasterCompany = async (companyId: number) => {
    try {
      await axios.put(`/api/vms-cloud/company/${companyId}/disable`);
      enqueueSuccess('Master Company foi desativada com sucesso');
      mutate(`/api/vms-cloud/companies`);
    } catch (error) {
      console.error('Erro ao desativar a Master Company:', error);

      if (axios.isAxiosError(error) && error?.response?.data?.message) {
        const errorMessage = getMessage(error.response.data.message);
        enqueueError(errorMessage);
      }

      throw error;
    }
  };

  const addNewUserInMasterCompany = async (values: CompanyNewData) => {
    try {
      await axios.post(`/api/vms-cloud/company/${values.masterCompany.id}/user`, values);
      enqueueSuccess('Usuário adicionado com sucesso');
      navigate('/vms');
      mutate(`/api/vms-cloud/companies`);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueError(`${getMessage(error?.response?.data?.message)}`);
      }
      throw error;
    }
  };

  const disabledOnboarding = async (companyId: number) => {
    /*
     * IMPLANTAÇÃO representa o status "true".
     * PRODUÇÃO representa o status "false".
     * O código abaixo desabilita a flag de onboarding na master company.
     * Isso altera o status da empresa de IMPLANTAÇÃO (IMPLANTAÇÃO) para PRODUÇÃO (PRODUÇÃO).
     * Importante: Esta mudança é permanente e não pode ser desfeita.
     */
    try {
      await axios.post(`/api/vms-cloud/company/${companyId}/onboarding`);

      enqueueSuccess('Implantação bem-sucedida!');

      mutate(`/api/vms-cloud/companies`);
    } catch (error) {
      console.error('Erro durante a implantação', error);

      if (axios.isAxiosError(error) && error?.response?.data?.message) {
        const errorMessage = getMessage(error.response.data.message);
        enqueueError(errorMessage);
      }

      throw error;
    }
  };

  return {
    createMasterCompany,
    updateMasterCompany,
    enableMasterCompany,
    disableMasterCompany,
    addNewUserInMasterCompany,
    disabledOnboarding,
  };
};
