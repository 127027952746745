import { LoadingButton } from '@mui/lab';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import { useFetch, useMessages } from 'hooks';
import React from 'react';
import { mutate } from 'swr';
import { MasterCompaniesApi } from 'types/MasterCompany.type';
import { format } from 'date-fns';

function MasterCompanies() {
  const { data } = useFetch<MasterCompaniesApi>('/api/master-company/list');
  const theme = useTheme();

  return (
    <Box
      sx={{
        overflow: 'hidden',
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Paper
        sx={{
          padding: theme.spacing(2),
        }}
      >
        <Typography variant="h4">Empresas na cloud</Typography>
        <Typography variant="h6">
          Lista de empresas que aguardam virada de My Security / Web Alarme para Cloud
        </Typography>
      </Paper>
      {data ? (
        <Paper
          sx={{
            marginTop: theme.spacing(3),
            display: 'flex',
            flex: '1 1 auto',
            overflow: 'hidden',
          }}
        >
          <TableContainer>
            <Table sx={{ minWidth: 650 }} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="right">Código</TableCell>
                  <TableCell>Razão social</TableCell>
                  <TableCell>Nome fantasia</TableCell>
                  <TableCell>Cidade</TableCell>
                  <TableCell>Data Registro</TableCell>
                  <TableCell>Ação</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.masterCompanyList.map((company) => (
                  <TableRow
                    key={company.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    hover
                  >
                    <TableCell component="th" scope="row" align="right">
                      {company.id}
                    </TableCell>
                    <TableCell>{company.razaoSocial}</TableCell>
                    <TableCell>{company.nomeFantasia}</TableCell>
                    <TableCell>{company.cidade}</TableCell>
                    <TableCell>{format(new Date(company.dataCadastro), 'dd/MM/yyyy')}</TableCell>
                    <TableCell align="center">
                      <VirarChaveButton masterCompanyId={company.id} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Paper
          sx={{
            padding: theme.spacing(2),
          }}
        >
          <Typography variant="h6">Sem dados para mostrar</Typography>
        </Paper>
      )}
    </Box>
  );
}

function VirarChaveButton({ masterCompanyId }: { masterCompanyId: number }) {
  const [sending, setSending] = React.useState(false);
  const { enqueueError, enqueueSuccess } = useMessages();
  const handleVirarChave = React.useCallback(async () => {
    setSending(true);
    try {
      await axios.post(`/api/master-company/${masterCompanyId}/virar-chave`);
      mutate<MasterCompaniesApi>(
        '/api/master-company/list',
        (data) =>
          data && {
            ...data,
            masterCompanyList: data.masterCompanyList.filter(
              (masterCompany) => masterCompany.id !== masterCompanyId
            ),
          }
      );
      enqueueSuccess('Chave virada com sucesso!');
      setSending(false);
    } catch (error) {
      setSending(false);
      if (axios.isAxiosError(error))
        enqueueError(`Erro ao virar a chave\n${error?.response?.data?.message || ''}`);
      throw error;
    }
  }, [masterCompanyId, enqueueError, enqueueSuccess]);

  return (
    <LoadingButton loading={sending} onClick={() => handleVirarChave()} variant="contained" sx={{ whiteSpace: 'nowrap' }}>
      Virar chave
    </LoadingButton>
  );
}

export default MasterCompanies;
