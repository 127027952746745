import { Box, Paper, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from 'hooks';
import { CompanyDetailApi } from 'types/ClientDetails.type';
import ClientNewForm from './ClientNewForm';

function ClientNew() {
  const theme = useTheme();
  let { companyId } = useParams<{ companyId: string }>();

  const { data } = useFetch<CompanyDetailApi>(`/api/company/${companyId}`);

  return (
    <Box
      sx={{
        overflow: 'hidden',
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Paper
        sx={{
          padding: theme.spacing(2),
        }}
      >
        <Typography variant="h4">Cadastro de nova master company</Typography>
        <Typography variant="h6">Confirme os dados abaixo</Typography>
      </Paper>
      <Paper
        sx={{
          marginTop: theme.spacing(3),
          padding: theme.spacing(2),
          overflow: 'hidden',
          display: 'flex',
        }}
      >
        {data ? (
          <ClientNewForm
            company={data.details.company}
            additional={data.details.additional}
            encoded={data.details.encoded}
          />
        ) : (
          <Typography variant="h4">Company não encontrada</Typography>
        )}
      </Paper>
    </Box>
  );
}

export default ClientNew;
