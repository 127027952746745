import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { CANARY_FUNCTIONALITYS } from 'consts';
import { Functionality } from 'types/Functionality.type';

function SelectLicenseType({
  value,
  onChange,
  error,
  helperText,
  placeholder,
  name,
}: {
  value: Functionality[];
  onChange: (event: SelectChangeEvent<Functionality[]>, child: React.ReactNode) => void;
  error?: boolean;
  helperText?: string[] | false;
  placeholder?: string;
  name: string;
}) {
  return (
    <FormControl
      variant="outlined"
      fullWidth
      {...{ error }}
      sx={{
        marginLeft: '8px',
      }}
      margin="normal"
    >
      <InputLabel id="select-functionality-label">Funcionalidade</InputLabel>
      <Select<Functionality[]>
        {...{ name, placeholder }}
        labelId="select-functionality-label"
        id="select-functionality"
        onChange={onChange}
        value={value}
        label="Funcionalidade"
        multiple
      >
        {CANARY_FUNCTIONALITYS.map((func) => (
          <MenuItem key={func.value} value={func.value}>
            {func.label}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default SelectLicenseType;
