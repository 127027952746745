import { Apartment } from '@mui/icons-material';
import {
  Box,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  useTheme,
  Link,
  Button,
} from '@mui/material';
import FetchBoundary from 'components/FetchBoundary';
import { userKey } from 'consts';
import React from 'react';
import {
  Link as RouterLink,
  LinkProps,
  Navigate,
  Outlet,
  useMatch,
  useResolvedPath,
} from 'react-router-dom';
import { useLocalStorage } from 'react-use';

const drawerWidth = 260;

function AuthLayout() {
  const [user, setUser] = useLocalStorage(userKey, '');
  const theme = useTheme();

  return user ? (
    <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Typography variant="h5" noWrap color="white">
            Sigma Cloud Admin
          </Typography>
          <Button
            onClick={() => setUser('')}
            variant="contained"
            color="inherit"
            sx={{ color: theme.palette.text.secondary }}
          >
            Sair
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            <ItemMenu text="Clientes Segware" to="/company" />
            <ItemMenu text="Empresas masters" to="/masterCompany" />
            <ItemMenu text="Canary" to="/canary" />
            <ItemMenu text="Clientes VMS Cloud" to="/vms" />
            <ItemMenu text="Clientes Scond" to="/scond" />
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
      >
        <Toolbar />
        <FetchBoundary>
          <Outlet />
        </FetchBoundary>
      </Box>
    </Box>
  ) : (
    <Navigate to="/login" />
  );
}

function ItemMenu({ to, text, ...props }: LinkProps & { text: string }) {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });
  const theme = useTheme();

  return (
    <Link
      component={RouterLink}
      sx={{
        color: theme.palette.text.primary,
        textDecoration: 'none',
        textDecorationColor: 'none',
      }}
      {...{ to }}
      {...props}
    >
      <ListItem
        button
        selected={!!match}
        // sx={{ backgroundColor: match ? theme.palette.primary.light : undefined }}
      >
        <ListItemIcon>
          <Apartment
          // sx={{ color: match ? theme.palette.primary.contrastText : theme.palette.text.disabled }}
          />
        </ListItemIcon>
        <Typography
          variant={match ? 'h6' : undefined}
          // color={match ? theme.palette.primary.contrastText : undefined}
        >
          {text}
        </Typography>
      </ListItem>
    </Link>
  );
}

export default AuthLayout;
