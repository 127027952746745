import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Box,
  Button,
  Stack,
  useTheme,
} from '@mui/material';
import SelectMasterCompany from './SelectMasterCompany';
import SelectFunctionality from './SelectFunctionality';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useMessages } from 'hooks';
import { MasterCompanySelect } from 'types/MasterCompany.type'
import { Functionality } from 'types/Functionality.type'

function ClientNewForm() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { enqueueError, enqueueSuccess } = useMessages();

  const validationSchema = yup.object({
    masterCompany: yup.object({ id: yup.number(), }).required(),
    functionality: yup.array(yup.string().trim()).required(),
  });

  const initialValues: {
    masterCompany: MasterCompanySelect | null,
    functionality: Functionality[],
  } = {
    masterCompany: null,
    functionality: [],
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const { masterCompany, functionality } = values;
        await axios.post('/api/canary/create', { masterCompanyId: masterCompany?.id, functionality });
        setSubmitting(false);
        enqueueSuccess('Canary cadastrado com sucessso!');
        navigate('/canary');
      } catch (error) {
        if (axios.isAxiosError(error))
          enqueueError(`Erro ao cadastrar canary\n${error?.response?.data?.message || ''}`);
        throw error;
      }
    },
  });

  // eslint-disable-next-line no-console
  console.log(formik);

  return (
    <Box sx={{ overflow: 'auto', flex: '1 1 auto' }}>
      <Box component="form" onSubmit={formik.handleSubmit} sx={{ paddingRight: '8px' }}>
        <React.Suspense fallback={<SelectMasterCompany.Loading />}>
          <SelectMasterCompany
            name="masterCompany"
            value={formik.values.masterCompany}
            onChange={(value: MasterCompanySelect | null) => formik.setFieldValue('masterCompany', value)}
            error={formik.touched.masterCompany && Boolean(formik.errors.masterCompany)}
            helperText={formik.touched.masterCompany && formik.errors.masterCompany}
          />
        </React.Suspense>
        <SelectFunctionality
          name="functionality"
          value={formik.values.functionality}
          onChange={formik.handleChange}
          error={formik.touched.functionality && Boolean(formik.errors.functionality)}
        />
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
          sx={{ marginTop: theme.spacing(3) }}
        >
          <Button onClick={() => navigate('/canary')} size="large">
            Voltar
          </Button>
          <LoadingButton loading={formik.isSubmitting} type="submit" variant="contained" size="large">
            Salvar
          </LoadingButton>
        </Stack>
      </Box>
    </Box>
  );
}

export default ClientNewForm;
