export const getMessage = (key: string) => {
    if (key === 'username_already_in_use') {
        return 'Nome de usuário em uso';
    }

    if (key === 'email_already_in_use') {
        return 'Email em uso';
    }

    if (key === 'error_to_create_user_on_keycloak') {
        return 'Falha ao criar usuário no keycloak'
    }

    if (key === 'error_to_enable_user_on_keycloak') {
        return 'Falha ao Ativar usuário no Keycloak'
    }

    if (key === 'error_to_disable_user_on_keycloak') {
      return 'Falha ao Desativar usuário no Keycloak';
    }

    if (key === 'error_to_send_verify_email_to_keycloak') {

        return 'Falha ao enviar email de verificação para o Keycloak';
    }

    return `Erro desconhecido - ${key}`;
}