import { Company } from 'types/Client.type';

export const Permission = 'ADMIN';

export type User = {
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  permission: string; // only ADMIN
};

export type MasterCompany = {
  id: number;
  name: string;
  status: boolean;
  onboarding: boolean;
  alwaysPlay: boolean;
};

export type MasterCompanyWithVMS = Company & {
  existeNoVMS: boolean;
  status?: boolean;
  email: string;
};

export type CompanyNewData = User & {
  masterCompany: MasterCompany;
};

export type CompanyEditData = {
  id: number;
  masterCompany: MasterCompany;
};

export const DEFAULT_COMPANY: CompanyNewData = {
  firstName: '',
  lastName: '',
  userName: '',
  email: '',
  masterCompany: {
    name: '',
    status: true,
    onboarding: true,
    alwaysPlay: true,
    id: 0,
  },
  permission: Permission,
};

export const DEFAULT_USER: User = {
  firstName: '',
  lastName: '',
  userName: '',
  email: '',
  permission: Permission,
};

export interface MasterCompanyApiList {
  message: string;
  companies: MasterCompany[];
}

export interface MasterCompanyWithVMSApiList {
  message: string;
  companies: MasterCompanyWithVMS[];
}
