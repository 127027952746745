import { useVmsAPI } from 'pages/VMSCloud/hook/useVMS';
import { useNavigate, useParams } from 'react-router-dom';
import { CompanyNewData, DEFAULT_USER, MasterCompany } from '../../types';
import { Container, Header } from '../../components';
import { CompanyNewForm } from '../../forms';
import { useFetch } from 'hooks';

function CompanyNewUserPage() {
  const navigate = useNavigate();
  const { addNewUserInMasterCompany } = useVmsAPI();
  const { companyId } = useParams();
  const { data } = useFetch<MasterCompany>(`/api/vms-cloud/company/${companyId}`);

  const handleSubmit = async (values: CompanyNewData) => {
    await addNewUserInMasterCompany(values);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      <Header title="Novo Usuário VMS Cloud" subtitle={`Adicione um novo usuário na Master Company: ${data?.id}`} />

      <CompanyNewForm onBack={handleBack} onSave={handleSubmit} initialValues={{
        ...DEFAULT_USER,
        masterCompany: data as MasterCompany,
      }} />
    </Container>
  );
}

export default CompanyNewUserPage;
