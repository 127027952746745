import { Search } from '@mui/icons-material';
import {
  Box,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useFetch } from 'hooks';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SCONDIntegradorListApi } from 'types/SCOND.type';

export default function SCONDMaintanceIntegratorsPage() {
  const navigate = useNavigate();

  const { data } = useFetch<SCONDIntegradorListApi>(`/api/scond/integradores`);
  const [searchText, setSearchText] = React.useState('');

  const list = React.useMemo(() => {
    return data
      ? data.integradores.filter(integrador =>
          searchText
            ? integrador.nome?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
              integrador.razaoSocial?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
              integrador.id?.toString().includes(searchText?.toLowerCase()) ||
              integrador.cnpj
                ?.replaceAll('.', '')
                .replaceAll('/', '')
                .replaceAll('-', '')
                .includes(searchText.replaceAll('.', '').replaceAll('/', '').replaceAll('-', ''))
            : true
        )
      : [];
  }, [searchText, data]);

  return (
    <Box>
      <Header {...{ searchText, setSearchText }} />
      {list ? (
        <Paper
          sx={{
            mt: 3,
            display: 'flex',
            flex: '1 1 auto',
            overflow: 'hidden',
          }}
        >
          <TableContainer
            sx={{
              maxHeight: 'calc(100vh - 250px)',
            }}
          >
            <Table sx={{ minWidth: 650 }} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="right">Código</TableCell>
                  <TableCell>Razão social</TableCell>
                  <TableCell>Nome fantasia</TableCell>
                  <TableCell>CNPJ</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map(integrador => (
                  <TableRow
                    key={integrador.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                    hover
                    onClick={() => navigate(`/scond/maintance-integrators/${integrador.id}`)}
                  >
                    <TableCell component="th" scope="row" align="right">
                      {integrador.id}
                    </TableCell>
                    <TableCell>{integrador.razaoSocial}</TableCell>
                    <TableCell>{integrador.nome}</TableCell>
                    <TableCell>{integrador.cnpj}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Paper
          sx={{
            p: 2,
          }}
        >
          <Typography variant="h6">Sem dados para mostrar</Typography>
        </Paper>
      )}
    </Box>
  );
}

function Header({
  searchText,
  setSearchText,
}: {
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
}) {
  return (
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h4">Integradores</Typography>
        </Box>

        <Typography variant="h6">Lista de todos os integradores cadastrados no center</Typography>
        <TextField
          label="Busca"
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
          helperText="Busca por fantasia, nome ou CNPJ"
          sx={{ mt: 2 }}
        />
      </Box>
    </Paper>
  );
}
