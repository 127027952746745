import { Box, IconButton, Paper, Tooltip, Typography, useTheme } from '@mui/material';
import React from 'react';
import HelpIcon from '@mui/icons-material/Help';

interface Props {
  title: string;
  subtitle: string;
  children?: React.ReactNode;
  tooltipText?: string;
}
function Header({ title, subtitle, children, tooltipText }: Props) {
  const theme = useTheme();
  return (
    <Paper
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Typography variant="h4">{title}</Typography>
            {tooltipText && (
              <Tooltip title={tooltipText}>
                <IconButton>
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>

          <Typography variant="h6">{subtitle}</Typography>
        </Box>
        {children}
      </Box>
    </Paper>
  );
}
export default Header;
