import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  styled,
} from '@mui/material';
import { useMessages } from 'hooks';
import { useVmsAPI } from 'pages/VMSCloud/hook/useVMS';
import React from 'react';

interface OnboardingProps {
  company: {
    id: number;
    name: string;
  };
  openOnboardingModal: {
    id: number;
    status: boolean;
  };
  onCloseOnboardingModal: () => void;
}

export default function OnboardingDialogVMS({
  company,
  openOnboardingModal,
  onCloseOnboardingModal,
}: OnboardingProps) {
  const { disabledOnboarding } = useVmsAPI();
  const { enqueueError } = useMessages();

  const [loadingOnboarding, setLoadingOnboarding] = React.useState({
    id: 0,
    status: false,
  });

  const handleOnboarding = async (id: number) => {
    try {
      setLoadingOnboarding({
        id,
        status: true,
      });

      await disabledOnboarding(id);
    } catch (error: unknown) {
      if (error instanceof Error) enqueueError(`Falha na implantação: ${error.message}`);
      console.error(error);
    }

    setLoadingOnboarding({
      id,
      status: false,
    });

    onCloseOnboardingModal();
  };

  return (
    <Dialog
      open={company.id === openOnboardingModal.id && openOnboardingModal.status}
      onClose={onCloseOnboardingModal}
      aria-labelledby={`alert-dialog-title-${company.id}`}
      aria-describedby="alert-dialog-description"
      key={company.id}
    >
      <DialogTitle id={`alert-dialog-title-${company.id}`}>
        Confirmar Mudança para Ambiente de Produção
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          variant="body2"
          sx={{
            mb: 2,
          }}
        >
          <i>
            <b>
              {company.id} - {company.name}
            </b>
          </i>
        </DialogContentText>
        <DialogContentText>
          Você está prestes a mudar o status da operação de <InfoText>IMPLANTAÇÃO</InfoText> para{' '}
          <SuccessText>PRODUÇÃO</SuccessText>.
        </DialogContentText>
        <br />
        <DialogContentText>
          Certifique-se de que todas as configurações foram revisadas e testadas. Esta ação é{' '}
          <b>permanente</b> e <b>não poderá ser desfeita.</b>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            color: 'gray',
          }}
          onClick={onCloseOnboardingModal}
          disabled={company.id === loadingOnboarding.id && loadingOnboarding.status}
        >
          Cancelar
        </Button>
        <LoadingButton
          color="success"
          loading={company.id === loadingOnboarding.id && loadingOnboarding.status}
          onClick={() => handleOnboarding(company.id)}
          autoFocus
          variant="contained"
        >
          Implantar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

const InfoText = styled('span')(({ theme }) => ({
  color: theme.palette.info.main,
  fontWeight: 'bold',
}));

const SuccessText = styled('span')(({ theme }) => ({
  color: theme.palette.success.main,
  fontWeight: 'bold',
}));
