import { Box, Button, Paper, Stack, TextField, Typography } from '@mui/material';
import * as yup from 'yup';
import { useFetch, useMessages } from 'hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { SCONDIntegradorApi, SCONDIntegradorInfo } from 'types/SCOND.type';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';

export default function SCONDMaintanceIntegratorFormPage() {
  const { integradorId } = useParams<{
    integradorId: string;
  }>();
  const navigate = useNavigate();
  const { enqueueError, enqueueSuccess } = useMessages();

  const isNewIntegrador = integradorId === 'new';

  const { data: integradorData, mutate } = useFetch<SCONDIntegradorApi>(
    `/api/scond/integradores/${integradorId}`,
    {
      conditionFn: () => !isNewIntegrador,
    }
  );
  const integradorInfo = integradorData?.integrador;

  const validationSchema = yup.object({
    razaoSocial: yup.string().required(),
    nome: yup.string().required(),
    email: yup.string().required().email(),
    cnpj: yup.string().required(),
    appId: yup.string().required().max(50),
    oneSignal: yup.string().required().max(50),
    voipAppId: yup.string().max(50),
    voipOneSignal: yup.string().max(50),
  });
  const initialValues = {
    razaoSocial: integradorInfo?.razaoSocial,
    nome: integradorInfo?.nome,
    email: integradorInfo?.email,
    cnpj: integradorInfo?.cnpj,
    appId: integradorInfo?.appId,
    oneSignal: integradorInfo?.oneSignal,
    voipAppId: integradorInfo?.voipAppId,
    voipOneSignal: integradorInfo?.voipOneSignal,
    contratos: integradorInfo?.contratos,
    masterCompanyId: integradorInfo?.masterCompanyId,
    sigmaEmpresaId: integradorInfo?.sigmaEmpresaId,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await axios.put(`/api/scond/integradores/${integradorId}`, values);
        enqueueSuccess('Integrador modificado com sucesso!');
        mutate();
        navigate(-1);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          enqueueError(`Erro ao modificar\n${error?.response?.data?.message || ''}`);
        }
        throw error;
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
      <Header {...{ integradorInfo }} />
      <Paper
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{ p: 2, mt: 2, flex: '1 1 auto', overflow: 'auto' }}
      >
        <TextField
          id="razaoSocial"
          name="razaoSocial"
          label="Razão Social"
          value={formik.values.razaoSocial}
          onChange={formik.handleChange}
          error={formik.touched.razaoSocial && Boolean(formik.errors.razaoSocial)}
          helperText={formik.touched.razaoSocial && formik.errors.razaoSocial}
          margin="normal"
          fullWidth
        />
        <TextField
          id="nome"
          name="nome"
          label="Nome Fantasia"
          value={formik.values.nome}
          onChange={formik.handleChange}
          error={formik.touched.nome && Boolean(formik.errors.nome)}
          helperText={formik.touched.nome && formik.errors.nome}
          margin="normal"
          fullWidth
        />
        <TextField
          id="email"
          name="email"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          margin="normal"
          fullWidth
        />
        <TextField
          id="cnpj"
          name="cnpj"
          label="CNPJ"
          value={formik.values.cnpj}
          onChange={formik.handleChange}
          error={formik.touched.cnpj && Boolean(formik.errors.cnpj)}
          helperText={formik.touched.cnpj && formik.errors.cnpj}
          margin="normal"
          fullWidth
        />
        <TextField
          id="appId"
          name="appId"
          label="APP ID"
          value={formik.values.appId}
          onChange={formik.handleChange}
          error={formik.touched.appId && Boolean(formik.errors.appId)}
          helperText={formik.touched.appId && formik.errors.appId}
          margin="normal"
          fullWidth
        />
        <TextField
          id="oneSignal"
          name="oneSignal"
          label="One Signal"
          value={formik.values.oneSignal}
          onChange={formik.handleChange}
          error={formik.touched.oneSignal && Boolean(formik.errors.oneSignal)}
          helperText={formik.touched.oneSignal && formik.errors.oneSignal}
          margin="normal"
          fullWidth
        />
        <TextField
          id="voipAppId"
          name="voipAppId"
          label="VOIP - APP ID"
          value={formik.values.voipAppId}
          onChange={formik.handleChange}
          error={formik.touched.voipAppId && Boolean(formik.errors.voipAppId)}
          helperText={formik.touched.voipAppId && formik.errors.voipAppId}
          margin="normal"
          fullWidth
        />
        <TextField
          id="voipOneSignal"
          name="voipOneSignal"
          label="VOIP - One Signal"
          value={formik.values.voipOneSignal}
          onChange={formik.handleChange}
          error={formik.touched.voipOneSignal && Boolean(formik.errors.voipOneSignal)}
          helperText={formik.touched.voipOneSignal && formik.errors.voipOneSignal}
          margin="normal"
          fullWidth
        />
        <Stack
          spacing={2}
          direction="row"
          sx={{ justifyContent: 'flex-end', alignItems: 'center', mt: 3 }}
        >
          <Button onClick={() => navigate(-1)} size="large">
            Voltar
          </Button>
          <LoadingButton type="submit" variant="contained" size="large">
            Salvar
          </LoadingButton>
        </Stack>
      </Paper>
    </Box>
  );
}

function Header({ integradorInfo }: { integradorInfo?: SCONDIntegradorInfo }) {
  const title = `Integrador ${integradorInfo?.id} "${integradorInfo?.nome}"`;
  return (
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flex: '0 0 auto',
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Typography variant="h4">{title}</Typography>
        </Box>
      </Box>
    </Paper>
  );
}
