import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { useFetch } from 'hooks';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CompanyListApi } from 'types/Client.type';

function Clients() {
  const { data } = useFetch<CompanyListApi>('/api/company/list');
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        overflow: 'hidden',
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Paper
        sx={{
          padding: theme.spacing(2),
        }}
      >
        <Typography variant="h4">Clientes Segware</Typography>
        <Typography variant="h6">Lista de empresas cloud</Typography>
      </Paper>
      {data ? (
        <Paper
          sx={{
            marginTop: theme.spacing(3),
            display: 'flex',
            flex: '1 1 auto',
            overflow: 'hidden',
          }}
        >
          <TableContainer>
            <Table sx={{ minWidth: 650 }} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="right">Código</TableCell>
                  <TableCell>Razão social</TableCell>
                  <TableCell>Nome fantasia</TableCell>
                  <TableCell>Cidade</TableCell>
                  <TableCell>País</TableCell>
                  <TableCell align="center">Chave virada</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.companiesList.map((company) => (
                  <TableRow
                    key={company.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                    hover
                    onClick={() => navigate(`/company/new/${company.id}`)}
                  >
                    <TableCell component="th" scope="row" align="right">
                      {company.id}
                    </TableCell>
                    <TableCell>{company.razaoSocial}</TableCell>
                    <TableCell>{company.nomeFantasia}</TableCell>
                    <TableCell>{company.cidade}</TableCell>
                    <TableCell>{company.pais}</TableCell>
                    <TableCell align="center">
                      {company.chaveVirada ? (
                        <CheckBox color="primary" />
                      ) : (
                        <CheckBoxOutlineBlank />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Paper
          sx={{
            padding: theme.spacing(2),
          }}
        >
          <Typography variant="h6">Sem dados para mostrar</Typography>
        </Paper>
      )}
    </Box>
  );
}

export default Clients;
