import React, { useCallback } from 'react';
import {
  useSnackbar,
  ProviderContext,
  SnackbarMessage,
  OptionsObject,
  SnackbarKey,
} from 'notistack';
import { Button } from '@mui/material';

interface Messages {
  enqueueSuccess: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;
  enqueueError: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;
  enqueueInfo: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;
}

export default function useMessages(): ProviderContext & Messages {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const enqueueSuccess = (message: SnackbarMessage, options?: OptionsObject) =>
    enqueueSnackbar(message, {
      action: (key) => (
        <Button
          sx={{ color: 'white' }}
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          Dispensar
        </Button>
      ),
      ...options,
      variant: 'success',
    });
  const enqueueError = (message: SnackbarMessage, options?: OptionsObject) =>
    enqueueSnackbar(message, {
      persist: true,
      preventDuplicate: true,
      action: (key) => (
        <Button
          sx={{ color: 'white' }}
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          Dispensar
        </Button>
      ),
      ...options,
      variant: 'error',
    });
  const enqueueInfo = useCallback(
    (message: SnackbarMessage, options?: OptionsObject) =>
      enqueueSnackbar(message, {
        action: (key) => (
          <Button
            sx={{ color: 'white' }}
            onClick={() => {
              closeSnackbar(key);
            }}
          >
            Dispensar
          </Button>
        ),
        ...options,
        variant: 'info',
      }),
    [closeSnackbar, enqueueSnackbar]
  );

  return {
    enqueueSnackbar,
    closeSnackbar,
    enqueueSuccess,
    enqueueError,
    enqueueInfo,
  };
}
