import { Box } from '@mui/material';
import React from 'react';

interface Props {
  children: React.ReactNode;
}
function Container({ children }: Props) {
  return (
    <Box
      sx={{
        overflow: 'hidden',
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {children}
    </Box>
  );
}

export default Container;
