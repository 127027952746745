import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { useFetch } from 'hooks';
import { Header } from 'pages/VMSCloud/components';
import { MasterCompanyWithVMS, MasterCompanyWithVMSApiList } from 'pages/VMSCloud/types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function CompanyListPage() {
  const { data } = useFetch<MasterCompanyWithVMSApiList>('/api/vms-cloud/listAll');
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box>
      <Header
        title="Clientes Segware"
        subtitle="Lista com todos os clientes cadastrados na Segware"
      >
        <Box
          sx={{
            display: 'flex',
            gap: theme.spacing(2),
            flexDirection: 'column',
          }}
        >
          <Button variant="contained" onClick={() => navigate('/vms/new')} color="primary">
            Criar Master Company
          </Button>
          <Button variant="contained" onClick={() => navigate('/vms/list')} color="success">
            Listar somente empresas VMS
          </Button>
        </Box>
      </Header>
      {data ? (
        <Paper
          sx={{
            marginTop: theme.spacing(3),
            display: 'flex',
            flex: '1 1 auto',
            overflow: 'hidden',
          }}
        >
          <TableContainer
            sx={{
              maxHeight: 'calc(100vh - 300px)',
            }}
          >
            <Table sx={{ minWidth: 650, maxHeight: '100%' }} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell width="100px" align="center">
                    Código
                  </TableCell>
                  <TableCell>Nome Fantasia</TableCell>
                  <TableCell>Razão Social</TableCell>
                  <TableCell>Existe no VMS?</TableCell>
                  <TableCell align="center">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.companies.map((company) => (
                  <TableRow
                    key={company.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                    hover
                  >
                    <TableCell
                      component="th"
                      align="center"
                      width="5%"
                      onClick={() => navigate(`/vms/${company.id}/view`)}
                    >
                      {company.id}
                    </TableCell>
                    <TableCell width="10%" onClick={() => navigate(`/vms/${company.id}/view`)}>
                      {company.nomeFantasia}
                    </TableCell>
                    <TableCell width="30%">{company.razaoSocial}</TableCell>
                    <TableCell width="10%">{company.existeNoVMS ? 'Sim' : 'Não'}</TableCell>
                    <TableCell align="center" width="10%">
                      {company.existeNoVMS ? (
                        <Button
                          variant="contained"
                          fullWidth
                          color="primary"
                          onClick={() => navigate(`/vms/${company.id}/new-user`)}
                        >
                          Novo usuário
                        </Button>
                      ) : (
                        <AddCLientButton clientData={company} />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Paper
          sx={{
            padding: theme.spacing(2),
          }}
        >
          <Typography variant="h6">Sem dados para mostrar</Typography>
        </Paper>
      )}
    </Box>
  );
}

interface AddClientProps {
  clientData: MasterCompanyWithVMS;
}
const AddCLientButton = ({
  clientData,
}:AddClientProps) => {
  const navigate = useNavigate();
  const handleClick = () => {
    
    navigate(
      `/vms/new/${clientData.id}`
    );
  }
  return (
    <Button variant="contained" color="success" onClick={handleClick} fullWidth>
      Adicionar ao VMS
    </Button>
  );
};
export default CompanyListPage;
