import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { SCONDCompany, SCONDCompanyListApi } from 'types/SCOND.type';
import { KeyedMutator } from 'swr';
import axios from 'axios';
import { Dialog, DialogActions, DialogContent, DialogTitle, Menu } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, { useCallback, useState } from 'react';
import { centerWebURL } from 'consts';
import { useMessages } from 'hooks';

export default function MenuListComposition({
  company,
  mutate,
}: Readonly<{
  company: SCONDCompany;
  mutate: KeyedMutator<SCONDCompanyListApi>;
}>) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [startAddingCompany, setStartAddingCompany] = useState(0);
  const [startRemovingCompany, setStartRemovingCompany] = useState('');
  const [doing, setDoing] = useState(false);
  const { enqueueError } = useMessages();

  React.useEffect(
    function closeOnInitiateAnAction() {
      if (startAddingCompany || startRemovingCompany) {
        handleClose();
      }
    },
    [startAddingCompany, startRemovingCompany]
  );

  function goScond(id: number) {
    window.open(`${centerWebURL}/#/verify-redirect/${id}`, '_blank');
  }

  const doAction = useCallback(async () => {
    setDoing(true);
    try {
      const masterCompanyId = startAddingCompany || startRemovingCompany;
      await axios[startAddingCompany ? 'post' : 'delete'](`/api/scond/${masterCompanyId}`);
      mutate();
      // {
      //   message: '',
      //   companiesList:
      //     data?.companiesList.map(company =>
      //       company.id === masterCompanyId
      //         ? {
      //             ...company,
      //             scond: {
      //               nome: company.nomeFantasia,
      //               id: company.id.toString(),
      //               CNPJ: company.nomeFantasia,
      //               razaoSocial: company.razaoSocial,
      //             },
      //           }
      //         : company
      //     ) ?? [],
      // },
      // { revalidate: true }
    } catch {
      enqueueError('Erro ao executar operação');
    } finally {
      setDoing(false);
      setStartRemovingCompany('');
      setStartAddingCompany(0);
    }
  }, [startAddingCompany, startRemovingCompany, mutate, enqueueError]);

  if (!company.scondIntegradorId) {
    return null;
  }

  return (
    <>
      <Stack direction="row" spacing={2} sx={{ width: 25 }}>
        <Button
          id="basic-button"
          aria-controls={open ? `company-actions-menu-${company.empresaId}` : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <MoreVertIcon />
        </Button>
        <Menu
          id={`company-actions-menu-${company.empresaId}`}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            onClick={() => {
              goScond(company.scondIntegradorId ?? 0);
              handleClose();
            }}
          >
            Configurar SCOND
          </MenuItem>
          <MenuItem
            disabled
            onClick={() => setStartRemovingCompany(company.scondIntegradorId?.toString() ?? '')}
          >
            Remover da SCOND
          </MenuItem>
        </Menu>
      </Stack>
      <ConfirmAction
        open={Boolean(startRemovingCompany || startAddingCompany)}
        onClose={() =>
          startAddingCompany ? setStartAddingCompany(0) : setStartRemovingCompany('')
        }
        handleConfirm={doAction}
        doing={doing}
      />
    </>
  );
}

function ConfirmAction({
  open,
  onClose,
  handleConfirm,
  doing,
}: Readonly<{
  open: boolean;
  onClose: () => void;
  handleConfirm: () => void;
  doing: boolean;
}>) {
  return (
    <Dialog {...{ open }} onClose={onClose}>
      <DialogTitle>Confirma</DialogTitle>
      <DialogContent>Confirma operação?</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={() => handleConfirm()}
          loading={doing}
        >
          Confirmar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
