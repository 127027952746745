import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
  Button,
} from '@mui/material';
import { useFetch } from 'hooks';
import { SCONDMasterCompanyListApi } from 'types/SCOND.type';
import React from 'react';
import { Search } from '@mui/icons-material';
import { Link } from 'react-router-dom';

function SCONDCompanyListPage() {
  const { data } = useFetch<SCONDMasterCompanyListApi>('/api/scond/list');
  const [searchText, setSearchText] = React.useState('');

  const list = React.useMemo(() => {
    return data
      ? data.masterCompaniesList.filter(company =>
          searchText
            ? company.nomeFantasia?.toLowerCase().includes(searchText?.toLowerCase()) ||
              company.razaoSocial?.toLowerCase().includes(searchText?.toLowerCase()) ||
              company.id?.toString().includes(searchText?.toLowerCase()) ||
              company.cnpj
                .replaceAll('.', '')
                .replaceAll('/', '')
                .replaceAll('-', '')
                .includes(searchText.replaceAll('.', '').replaceAll('/', '').replaceAll('-', ''))
            : true
        )
      : [];
  }, [searchText, data]);

  return (
    <Box>
      <Header {...{ searchText, setSearchText }} />
      {list ? (
        <Paper
          sx={{
            mt: 3,
            display: 'flex',
            flex: '1 1 auto',
            overflow: 'hidden',
          }}
        >
          <TableContainer
            sx={{
              maxHeight: 'calc(100vh - 250px)',
            }}
          >
            <Table sx={{ minWidth: 650 }} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="right">Código</TableCell>
                  <TableCell>Razão social</TableCell>
                  <TableCell>Nome fantasia</TableCell>
                  <TableCell align="left">Ação</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map(company => (
                  <TableRow
                    key={company.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                    hover
                  >
                    <TableCell component="th" scope="row" align="right">
                      {company.id}
                    </TableCell>
                    <TableCell>{company.razaoSocial}</TableCell>
                    <TableCell>{company.nomeFantasia}</TableCell>
                    <TableCell align="left">
                      <Link to={`/scond/segware-integration/${company.id}/companies`}>
                        <Button>Listar empresas</Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Paper
          sx={{
            p: 2,
          }}
        >
          <Typography variant="h6">Sem dados para mostrar</Typography>
        </Paper>
      )}
    </Box>
  );
}

function Header({
  searchText,
  setSearchText,
}: {
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
}) {
  return (
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Typography variant="h4">Clientes Segware</Typography>
        </Box>

        <Typography variant="h6">Lista com todos os clientes cadastrados na Segware</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          flexDirection: 'column',
        }}
      >
        <TextField
          label="Busca"
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
          helperText="Busca por fantasia, nome ou CNPJ"
        />
      </Box>
    </Paper>
  );
}

export default SCONDCompanyListPage;
