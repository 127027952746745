import { Box, Button, Paper, Switch, TextField, Typography, useTheme } from '@mui/material';
import { CompanyNewData } from '../../types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';

interface Props {
  onSave: (values: CompanyNewData) => void;
  onBack: () => void;
  initialValues: CompanyNewData;
}
function NewCompanyForm({ onSave, onBack, initialValues }: Props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      masterCompany: yup.object({
        alwaysPlay: yup.bool(),
        name: yup.string().required('Obrigatório').trim(),
        id: yup.number().required('Obrigatório'),
      }),
      firstName: yup.string().required('Obrigatório').trim(),
      lastName: yup.string().required('Obrigatório').trim(),
      userName: yup.string().required('Obrigatório').trim(),
      email: yup.string().required('Obrigatório').trim(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        await onSave(values);
        navigate('/vms');
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    },
  });

  return (
    <Paper
      sx={{
        marginTop: theme.spacing(3),
        padding: theme.spacing(2),
        overflow: 'hidden',
        display: 'flex',
      }}
    >
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        style={{
          width: '100%',
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            overflow: 'auto',
            flexDirection: 'column',
            pr: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
              mb: 2,
            }}
          >
            <TextField
              id="masterCompany.id"
              name="masterCompany.id"
              label="ID da Master Company"
              value={formik.values.masterCompany.id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.masterCompany?.id && Boolean(formik.errors.masterCompany?.id)}
              helperText={formik.touched.masterCompany?.id && formik.errors.masterCompany?.id}
              margin="normal"
              fullWidth
            />

            <TextField
              id="masterCompany.name"
              name="masterCompany.name"
              label="Nome da Master Company"
              value={formik.values.masterCompany.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.masterCompany?.name && Boolean(formik.errors.masterCompany?.name)
              }
              helperText={formik.touched.masterCompany?.name && formik.errors.masterCompany?.name}
              margin="normal"
              fullWidth
            />
          </Box>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Typography
                variant="subtitle1"
                component="label"
                htmlFor="masterCompany.alwaysPlay"
                display="block"
                sx={_ => ({
                  cursor: 'pointer',
                  color: theme.palette.action.active,
                })}
              >
                Modo sempre em Play
              </Typography>
            </Box>

            <Switch
              id="masterCompany.alwaysPlay"
              name="masterCompany.alwaysPlay"
              value={formik.values.masterCompany.alwaysPlay}
              checked={formik.values.masterCompany.alwaysPlay}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Box>
          <TextField
            id="firstName"
            name="firstName"
            onBlur={formik.handleBlur}
            label="Nome"
            sx={{ mt: 3 }}
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
            margin="normal"
            fullWidth
          />

          <TextField
            id="lastName"
            name="lastName"
            label="Sobrenome"
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
            margin="normal"
            fullWidth
          />

          <TextField
            id="userName"
            name="userName"
            label="Nome de usuário"
            onBlur={formik.handleBlur}
            value={formik.values.userName}
            onChange={formik.handleChange}
            error={formik.touched.userName && Boolean(formik.errors.userName)}
            helperText={formik.touched.userName && formik.errors.userName}
            margin="normal"
            fullWidth
          />

          <TextField
            id="email"
            name="email"
            type="email"
            label="E-mail"
            value={formik.values.email}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            margin="normal"
            fullWidth
          />
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: theme.spacing(2),
            gap: theme.spacing(2),
          }}
        >
          <Button onClick={onBack}>Voltar</Button>
          <LoadingButton
            loading={formik.isSubmitting}
            type="submit"
            variant="contained"
            size="large"
            disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
          >
            Salvar
          </LoadingButton>
        </Box>
      </Box>
    </Paper>
  );
}
export default NewCompanyForm;
