import { LoadingButton } from '@mui/lab';
import { Box, Button, Paper, Switch, TextField, Typography, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import { MasterCompany } from 'pages/VMSCloud/types';
import * as yup from 'yup';

interface Props {
  onSave: (values: { id: number; name: string; alwaysPlay: boolean }) => Promise<void>;
  onBack: () => void;
  initialValues: MasterCompany;
}
function EditCompanyForm({ initialValues, onBack, onSave }: Props) {
  const theme = useTheme();
  const formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      name: yup.string().required('Obrigatório'),
      alwaysPlay: yup.bool(),
    }),
    onSubmit: async (
      {
        id,
        name,
        alwaysPlay,
      }: {
        id: number;
        name: string;
        alwaysPlay: boolean;
      },
      { setSubmitting }
    ) => {
      try {
        setSubmitting(true);
        await onSave({
          id,
          name,
          alwaysPlay,
        });
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    },
  });

  return (
    <Paper
      sx={{
        marginTop: theme.spacing(3),
        padding: theme.spacing(2),
        overflow: 'hidden',
        display: 'flex',
      }}
    >
      <Box
        component="form"
        style={{
          width: '100%',
        }}
        onSubmit={formik.handleSubmit}
      >
        <TextField
          id="name"
          name="name"
          label="Nome da Master Company"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          margin="normal"
          fullWidth
        />

        <Box
          sx={{
            width: '100%',
            mt: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography
              variant="subtitle1"
              component="label"
              htmlFor="alwaysPlay"
              display="block"
              sx={_ => ({
                cursor: 'pointer',
                color: theme.palette.action.active,
              })}
            >
              Modo sempre em Play
            </Typography>
          </Box>

          <Switch
            id="alwaysPlay"
            name="alwaysPlay"
            value={formik.values.alwaysPlay}
            checked={formik.values.alwaysPlay}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: theme.spacing(2),
            gap: theme.spacing(2),
          }}
        >
          <Button onClick={onBack}>Voltar</Button>
          <LoadingButton
            loading={formik.isSubmitting}
            variant="contained"
            size="large"
            disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
            type="submit"
          >
            Salvar
          </LoadingButton>
        </Box>
      </Box>
    </Paper>
  );
}

export default EditCompanyForm;
