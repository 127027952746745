import { Search } from '@mui/icons-material';
import {
  Box,
  Button,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useFetch } from 'hooks';
import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { SCONDCompanyInfoApi, SCONDIntegradorCompanyListApi } from 'types/SCOND.type';

export default function SCONDIntegratePage() {
  const { masterCompanyId, companyId } = useParams<{
    masterCompanyId: string;
    companyId: string;
  }>();
  const navigate = useNavigate();

  const { data } = useFetch<SCONDIntegradorCompanyListApi>(
    `/api/scond/master-company/${masterCompanyId}/companies/${companyId}/integradores`
  );
  const [searchText, setSearchText] = React.useState('');

  const list = React.useMemo(() => {
    return data
      ? data.integradores.filter(integrador =>
          searchText
            ? integrador.nome?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
              integrador.razaoSocial?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
              integrador.id?.toString().includes(searchText?.toLowerCase()) ||
              integrador.cnpj
                ?.replaceAll('.', '')
                .replaceAll('/', '')
                .replaceAll('-', '')
                .includes(searchText.replaceAll('.', '').replaceAll('/', '').replaceAll('-', ''))
            : true
        )
      : [];
  }, [searchText, data]);

  return (
    <Box>
      <Header company={data?.company} {...{ searchText, setSearchText }} />
      {list ? (
        <Paper
          sx={{
            mt: 3,
            display: 'flex',
            flex: '1 1 auto',
            overflow: 'hidden',
          }}
        >
          <TableContainer
            sx={{
              maxHeight: 'calc(100vh - 250px)',
            }}
          >
            <Table sx={{ minWidth: 650 }} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="right">Código</TableCell>
                  <TableCell>Razão social</TableCell>
                  <TableCell>Nome fantasia</TableCell>
                  <TableCell>CNPJ</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map(integrador => (
                  <TableRow
                    key={integrador.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                    hover
                    onClick={() =>
                      navigate(
                        `/scond/segware-integration/${data?.company?.masterCompanyId}/companies/${data?.company?.companyId}/integrate/${integrador.id}`
                      )
                    }
                  >
                    <TableCell component="th" scope="row" align="right">
                      {integrador.id}
                    </TableCell>
                    <TableCell>{integrador.razaoSocial}</TableCell>
                    <TableCell>{integrador.nome}</TableCell>
                    <TableCell>{integrador.cnpj}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Paper
          sx={{
            p: 2,
          }}
        >
          <Typography variant="h6">Sem dados para mostrar</Typography>
        </Paper>
      )}
    </Box>
  );
}

function Header({
  company,
  searchText,
  setSearchText,
}: {
  company?: SCONDCompanyInfoApi;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
}) {
  return (
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h4">Integradores</Typography>
          <Link
            to={`/scond/segware-integration/${company?.masterCompanyId}/companies/${company?.companyId}/integrate/new`}
          >
            <Button variant="contained">Novo</Button>
          </Link>
        </Box>

        <Typography variant="h6">
          Selecione ou crie um integrador para ser integrado com a empresa "{company?.companyId} -{' '}
          {company?.companyRazao}" da master "{company?.masterCompanyId} -{' '}
          {company?.masterRazaoSocial}"
        </Typography>
        <TextField
          label="Busca"
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
          helperText="Busca por fantasia, nome ou CNPJ"
          sx={{ mt: 2 }}
        />
      </Box>
    </Paper>
  );
}
