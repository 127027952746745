import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useFetch } from 'hooks';
import { CompanyDetailApi } from 'types/ClientDetails.type';
import { ClientViewForm } from 'pages/VMSCloud/forms';
import { Container, Header } from 'pages/VMSCloud/components';

function CompanyViewPage() {
  const { companyId } = useParams();

  const { data } = useFetch<CompanyDetailApi>(`/api/company/${companyId}`);
  return (
    <Container>
      <Header
        title="Detalhes do cliente"
        subtitle={`Detalhes do cliente no Cloud`}
      />
      {data ? (
        <ClientViewForm
          company={data.details.company}
          additional={data.details.additional}
          encoded={data.details.encoded}
        />
      ) : (
        <Typography variant="h4">Company não encontrada</Typography>
      )}
    </Container>
  );
}
export default CompanyViewPage;
