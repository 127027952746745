import React from 'react';
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  Typography,
  useTheme,
} from '@mui/material';

function SelectFetchLoading({ label }: { label: string }) {
  const theme = useTheme();

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="select-loading-label">
        <Box display="flex">
          <Typography
            color="textSecondary"
            sx={{
              paddingRight: theme.spacing(1),
            }}
          >
            {`${label}...`}
          </Typography>
          <CircularProgress size={20} />
        </Box>
      </InputLabel>
      <Select labelId="select-loading-label" id="select-loading" value={[]} label={`${label}...`}>
        <MenuItem>
          <Checkbox checked={false} />
          <ListItemText>
            <Skeleton />
          </ListItemText>
        </MenuItem>
        <MenuItem>
          <Checkbox checked={false} />
          <ListItemText>
            <Skeleton />
          </ListItemText>
        </MenuItem>
        <MenuItem>
          <Checkbox checked={false} />
          <ListItemText>
            <Skeleton />
          </ListItemText>
        </MenuItem>
      </Select>
    </FormControl>
  );
}

export default SelectFetchLoading;
