import { useVmsAPI } from 'pages/VMSCloud/hook/useVMS';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_COMPANY, CompanyNewData } from '../../types';
import { Container, Header } from '../../components';
import { CompanyNewForm } from '../../forms';

function CompanyNewPage() {
  const navigate = useNavigate();
  const { createMasterCompany } = useVmsAPI();

  const handleSubmit = async (values: CompanyNewData) => {
    await createMasterCompany(values);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      <Header title="Nova Master Company" subtitle="Crie uma nova Master Company no VMS Cloud" />
      <CompanyNewForm onBack={handleBack} onSave={handleSubmit} initialValues={DEFAULT_COMPANY} />
    </Container>
  );
}

export default CompanyNewPage;
