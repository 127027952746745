import { Box } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import AuthLayout from 'components/AuthLayout';
import MaterialUiTheme from 'config/MaterialUITheme';
import GlobalSigmaAdminStyles from 'GloablSigmaAdminStyles';
import { SnackbarProvider } from 'notistack';
import ClientNew from 'pages/ClientNew';
import Clients from 'pages/Clients';
import { Routes, Route, BrowserRouter, Navigate, Outlet } from 'react-router-dom';
import Login from './pages/Login';
import MasterCompanies from './pages/MasterCompanies';
import Canary from './pages/Canary';
import CanaryNew from 'pages/CanaryNew';
import { VMS } from 'pages/VMSCloud';
import SCONDCompanyListPage from 'pages/SCONDCompanyListPage';
import SCONDCompaniesFromMasterPage from 'pages/SCONDCompaniesFromMasterPage';
import SCONDIntegratePage from 'pages/SCONDIntegratePage';
import SCONDIntegrateFormPage from 'pages/SCONDIntegrateFormPage';
import SCondPage from 'pages/SCondPage';
import SCONDMaintanceIntegratorsPage from 'pages/SCONDMaintanceIntegratorsPage';
import SCONDMaintanceIntegratorFormPage from 'pages/SCONDMaintanceIntegratorFormPage';
import SCONDAccountMigrationPage from 'pages/SCONDAccountMigrationPage';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      whiteSpace: 'pre', // to show breaklines in messages
    },
  })
);

export default function App() {
  const classes = useStyles();

  return (
    <MaterialUiTheme>
      <GlobalSigmaAdminStyles />
      <SnackbarProvider
        maxSnack={5}
        classes={{
          root: classes.root,
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route index element={<Navigate to="/login" />} />
            <Route path="/" element={<Layout />}>
              <Route path="login" element={<Login />} />
              <Route path="" element={<AuthLayout />}>
                <Route path="/company" element={<Clients />} />
                <Route path="/company/new/:companyId" element={<ClientNew />} />
                <Route path="/masterCompany" element={<MasterCompanies />} />
                <Route path="/canary" element={<Canary />} />
                <Route path="/canary/new" element={<CanaryNew />} />
                <Route path="/vms" element={<VMS.CompanyListPage />} />
                <Route path="/vms/new" element={<VMS.CompanyNewPage />} />
                <Route path="/vms/new/:companyId" element={<VMS.CompanyVinculatedNewPage />} />
                <Route path="/vms/:companyId/view" element={<VMS.CompanyViewPage />} />
                <Route path="/vms/:companyId" element={<VMS.CompanyEditPage />} />
                <Route path="/vms/:companyId/new-user" element={<VMS.CompanyNewUserPage />} />
                <Route path="/vms/list" element={<VMS.CompanyVMSListPage />} />
                <Route path="/scond" element={<SCondPage />}>
                  <Route path="/scond/segware-integration" element={<SCONDCompanyListPage />} />
                  <Route
                    path="/scond/segware-integration/:masterCompanyId/companies"
                    element={<SCONDCompaniesFromMasterPage />}
                  />
                  <Route
                    path="/scond/segware-integration/:masterCompanyId/companies/:companyId/integrate"
                    element={<SCONDIntegratePage />}
                  />
                  <Route
                    path="/scond/segware-integration/:masterCompanyId/companies/:companyId/integrate/:integradorId"
                    element={<SCONDIntegrateFormPage />}
                  />
                  <Route
                    path="/scond/maintance-integrators"
                    element={<SCONDMaintanceIntegratorsPage />}
                  />
                  <Route
                    path="/scond/maintance-integrators/:integradorId"
                    element={<SCONDMaintanceIntegratorFormPage />}
                  />
                  <Route path="/scond/account-migration" element={<SCONDAccountMigrationPage />} />
                </Route>
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </SnackbarProvider>
    </MaterialUiTheme>
  );
}

function Layout() {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
      }}
    >
      <Outlet />
    </Box>
  );
}
