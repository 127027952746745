import { Box, Typography } from '@mui/material';
import { useFetch } from 'hooks';
import { Header } from 'pages/VMSCloud/components';
import { CompanyNewForm } from 'pages/VMSCloud/forms';
import { useVmsAPI } from 'pages/VMSCloud/hook/useVMS';
import { CompanyNewData, Permission } from 'pages/VMSCloud/types';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CompanyDetailApi } from 'types/ClientDetails.type';

function CompanyVinculatedNewPage() {
  const { companyId } = useParams();
  const { data } = useFetch<CompanyDetailApi>(`/api/company/${companyId}`);
  const { createMasterCompany } = useVmsAPI();
  const navigate = useNavigate();

  const DEFAULT_VALUES: CompanyNewData = React.useMemo(() => {
    return {
      masterCompany: {
        id: data?.details.company.id || 0,
        name: data?.details.company.razaoSocial || '',
        status: true,
        onboarding: true,
        alwaysPlay: false,
      },
      email: data?.details.company.email || '',
      firstName: '',
      lastName: '',
      userName: '',
      permission: Permission,
    };
  }, [data]);

  const handleSave = async (values: CompanyNewData) => {
    await createMasterCompany(values);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Box>
      <Header
        title="Novo Cliente VMS Cloud"
        subtitle={`Adicionar cliente: ${companyId} ao VMS Cloud`}
      />
      {data ? (
        <CompanyNewForm onSave={handleSave} onBack={handleBack} initialValues={DEFAULT_VALUES} />
      ) : (
        <Typography variant="h4">Company não encontrada</Typography>
      )}
    </Box>
  );
}
export default CompanyVinculatedNewPage;
