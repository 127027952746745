import { useFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { CompanyAdditional, CompanyDetail } from 'types/ClientDetails.type';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
  TextField,
  useTheme,
} from '@mui/material';
import SelectLanguage from './SelectLanguage';
import SelectCountry from './SelectCountry';
import SelectLicenseType from './SelectLicenseType';
import { LocalizationProvider, DatePicker, LoadingButton } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useMessages } from 'hooks';

function ClientNewForm({
  company,
  additional,
  encoded,
}: {
  company: CompanyDetail;
  additional?: CompanyAdditional;
  encoded: string;
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { enqueueError, enqueueSuccess } = useMessages();

  const validationSchema = yup.object({
    id: yup.number(),
    razaoSocial: yup.string().trim().required(),
    nomeFantasia: yup.string().trim().required(),
    cidade: yup.string().trim(),
    fone01: yup.string().trim().required(),
    fone02: yup.string().trim(),
    pais: yup.string().trim().nullable(),
    complemento: yup.string().trim(),
    email: yup.string().trim().required(),
    cep: yup.string().trim(),
    rua: yup.string().trim(),
    inscricaoFederal: yup.string().trim().required(),
    chaveVirada: yup.boolean(),
    idDefaultLanguage: yup.number().required(),

    qtdAccount: yup.number().required(),
    licenseType: yup.string().trim().required(),
    expirationDate: yup.date().required(),

    isMigration: yup.boolean(),
    idPais: yup.number(),
    idMasterCompany: yup.number(),
    enderecoCobrancaPais: yup.number(),
    enderecoCobrancaRua: yup.string().trim(),
    numero: yup.string().trim(),
    enderecoCobrancaNumero: yup.string().trim(),
    enderecoCobrancaCep: yup.string().trim(),
    enderecoCobrancaComplemento: yup.string().trim(),
    authentication: yup.string().trim(),
    emailLicense: yup.string().trim(),
    encoded: yup.string().trim(),
  });

  const formik = useFormik({
    initialValues: {
      ...company,

      qtdAccount: additional?.qtdAccount || 0,
      licenseType: additional?.licenseType || '',
      expirationDate: additional?.expirationDate || null,

      isMigration: false,
      idPais: null,
      idMasterCompany: company.id,
      enderecoCobrancaPais: 0,
      enderecoCobrancaRua: '',
      enderecoCobrancaNumero: '',
      numero: '',
      enderecoCobrancaCep: '',
      enderecoCobrancaComplemento: '',
      authentication: '',
      emailLicense: company.email || '',
      encoded,
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        values.enderecoCobrancaPais = values.idPais || 0;
        values.enderecoCobrancaRua = values.rua;
        values.enderecoCobrancaNumero = values.numero;
        values.enderecoCobrancaCep = values.cep;
        values.enderecoCobrancaComplemento = values.complemento;
        await axios.post('/api/company/create', values);
        setSubmitting(false);
        enqueueSuccess('Empresa master cadastrada com sucessso!');
        navigate('/company');
      } catch (error) {
        if (axios.isAxiosError(error))
          enqueueError(`Erro ao cadastrar empresa master\n${error?.response?.data?.message || ''}`);
        throw error;
      }
    },
  });

  // eslint-disable-next-line no-console
  console.log(formik);

  return (
    <Box sx={{ overflow: 'auto', flex: '1 1 auto' }}>
      <Box component="form" onSubmit={formik.handleSubmit} sx={{ paddingRight: '8px' }}>
        <FormControl
          error={formik.touched.isMigration && Boolean(formik.errors.isMigration)}
          fullWidth
        >
          <FormControlLabel
            control={
              <Checkbox
                id="isMigration"
                name="isMigration"
                checked={formik.values.isMigration}
                onChange={formik.handleChange}
                color="primary"
              />
            }
            label="É uma migração?"
          />
          <FormHelperText>{formik.touched.isMigration && formik.errors.isMigration}</FormHelperText>
        </FormControl>
        <TextField
          id="id"
          name="id"
          label="Código"
          value={formik.values.id}
          onChange={formik.handleChange}
          error={formik.touched.id && Boolean(formik.errors.id)}
          helperText={formik.touched.id && formik.errors.id}
          margin="normal"
          disabled
          sx={{
            width: '160px',
          }}
        />
        <React.Suspense fallback={<SelectLanguage.Loading />}>
          <SelectLanguage
            name="idDefaultLanguage"
            value={formik.values.idDefaultLanguage}
            onChange={formik.handleChange}
            error={formik.touched.idDefaultLanguage && Boolean(formik.errors.idDefaultLanguage)}
            helperText={formik.touched.idDefaultLanguage && formik.errors.idDefaultLanguage}
          />
        </React.Suspense>
        <Stack direction="row">
          <TextField
            id="razaoSocial"
            name="razaoSocial"
            label="Razão Social"
            value={formik.values.razaoSocial}
            onChange={formik.handleChange}
            error={formik.touched.razaoSocial && Boolean(formik.errors.razaoSocial)}
            helperText={formik.touched.razaoSocial && formik.errors.razaoSocial}
            margin="normal"
            disabled
            fullWidth
          />
          <TextField
            id="nomeFantasia"
            name="nomeFantasia"
            label="Nome Fantasia"
            value={formik.values.nomeFantasia}
            onChange={formik.handleChange}
            error={formik.touched.nomeFantasia && Boolean(formik.errors.nomeFantasia)}
            helperText={formik.touched.nomeFantasia && formik.errors.nomeFantasia}
            margin="normal"
            disabled
            fullWidth
            sx={{ marginLeft: '8px' }}
          />
          <TextField
            id="inscricaoFederal"
            name="inscricaoFederal"
            label="CNPJ"
            value={formik.values.inscricaoFederal}
            onChange={formik.handleChange}
            error={formik.touched.inscricaoFederal && Boolean(formik.errors.inscricaoFederal)}
            helperText={formik.touched.inscricaoFederal && formik.errors.inscricaoFederal}
            margin="normal"
            disabled
            fullWidth
            sx={{ marginLeft: '8px' }}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            id="email"
            name="email"
            label="E-mail"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            margin="normal"
            disabled
            fullWidth
          />
          <TextField
            id="email"
            name="email"
            label="E-mail licença"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            margin="normal"
            disabled
            fullWidth
            sx={{ marginLeft: '8px' }}
          />
        </Stack>
        <Stack direction="row">
          <TextField
            id="fone01"
            name="fone01"
            label="Telefone"
            value={formik.values.fone01}
            onChange={formik.handleChange}
            error={formik.touched.fone01 && Boolean(formik.errors.fone01)}
            helperText={formik.touched.fone01 && formik.errors.fone01}
            margin="normal"
            disabled
            fullWidth
          />
          <TextField
            id="fone02"
            name="fone02"
            label="Telefone Adicional"
            value={formik.values.fone02}
            onChange={formik.handleChange}
            error={formik.touched.fone02 && Boolean(formik.errors.fone02)}
            helperText={formik.touched.fone02 && formik.errors.fone02}
            margin="normal"
            disabled
            fullWidth
            sx={{
              marginLeft: '8px',
            }}
          />
          <React.Suspense fallback={<SelectCountry.Loading />}>
            <SelectCountry
              name="idPais"
              value={formik.values.idPais}
              onChange={formik.handleChange}
              error={formik.touched.idPais && Boolean(formik.errors.idPais)}
              helperText={formik.touched.idPais && formik.errors.idPais}
              initialValueLabel={formik.values.pais}
              setValue={(value: number) => formik.setFieldValue('idPais', value)}
            />
          </React.Suspense>
        </Stack>
        <Stack direction="row">
          <TextField
            id="qtdAccount"
            name="qtdAccount"
            label="Quantidade de contas"
            value={formik.values.qtdAccount}
            onChange={formik.handleChange}
            error={formik.touched.qtdAccount && Boolean(formik.errors.qtdAccount)}
            helperText={formik.touched.qtdAccount && formik.errors.qtdAccount}
            margin="normal"
            disabled
            fullWidth
          />
          <SelectLicenseType
            name="licenseType"
            value={formik.values.licenseType}
            onChange={formik.handleChange}
            error={formik.touched.licenseType && Boolean(formik.errors.licenseType)}
            helperText={formik.touched.licenseType && formik.errors.licenseType}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Data de expiração"
              value={formik.values.expirationDate}
              onChange={date => formik.setFieldValue('expirationDate', date)}
              renderInput={params => (
                <TextField
                  {...params}
                  error={formik.touched.expirationDate && Boolean(formik.errors.expirationDate)}
                  helperText={formik.touched.expirationDate && formik.errors.expirationDate}
                  fullWidth
                  margin="normal"
                  sx={{ marginLeft: '8px' }}
                />
              )}
            />
          </LocalizationProvider>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
          sx={{ marginTop: theme.spacing(3) }}
        >
          <Button onClick={() => navigate('/company')} size="large">
            Voltar
          </Button>
          <LoadingButton type="submit" variant="contained" size="large">
            Salvar
          </LoadingButton>
        </Stack>
      </Box>
    </Box>
  );
}

export default ClientNewForm;
