import React from 'react';
import { GlobalStyles, useTheme } from '@mui/material';

function GlobalSigmaAdminStyles() {
  const theme = useTheme();

  return (
    <GlobalStyles
      styles={{
        body: {
          margin: 0,
        },
        '::-webkit-scrollbar-track': {
          border: `1px solid ${theme.palette.background.default}`,
          backgroundColor: `${theme.palette.background.default}`,
        },
        '::-webkit-scrollbar': {
          width: '0.3125rem',
          backgroundColor: `${theme.palette.background.paper}`,
        },

        '::-webkit-scrollbar-thumb': {
          backgroundColor: `${theme.palette.text.disabled}`,
        },
      }}
    />
  );
}

export default GlobalSigmaAdminStyles;
