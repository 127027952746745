/* eslint-disable no-param-reassign */
import axios from 'axios';
import { userKey } from 'consts';

export const BASE_URL = process.env.REACT_APP_BASE_URL;

axios.interceptors.request.use(config => {
  let loginData;
  try {
    loginData = JSON.parse(localStorage.getItem(userKey) || '{}');
    loginData = typeof loginData === 'string' ? JSON.parse(loginData) : loginData;
  } catch (error) {
    loginData = {};
  }
  config.baseURL = config.baseURL || BASE_URL;
  config.headers = { ...config.headers, userLogin: loginData?.user?.login };
  return config;
});

axios.interceptors.response.use(
  response => response,
  error => {
    // eslint-disable-next-line no-console
    console.error(error);
    return Promise.reject(error);
  }
);
